import type { IUserConfig } from "@/types/apiData.type";

export const getConfigParams = (experienceIdParam: string | null, deviceUuid: string | null): IUserConfig => {
  const experienceId = Number(experienceIdParam);
  const isValidCameraId = !Number.isNaN(experienceId) && experienceId > 0;

  return {
    experienceId: isValidCameraId ? experienceId : 0,
    venueId: 41,
    deviceUuid: deviceUuid || "",
  };
};
