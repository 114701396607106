import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import api from "@/api";
import { QueryKeyEnum } from "@/interfaces/enums/queryKeys.enum";

import type { IConfig, IApiDataState, IConfigDataDto, IUserConfig } from "../../types/apiData.type";
import { getConfigParams } from "./config.helpers";

const useConfigState = (): IApiDataState => {
  const [searchParams] = useSearchParams();

  const { data: config } = useQuery<IConfig>(
    QueryKeyEnum.config,
    () =>
      axios.get(`${window.location.origin}${api.config}`).then((res: AxiosResponse<IConfigDataDto>) => {
        const experienceIdParam: string | null = searchParams.get("experienceId");
        const deviceUuid: string | null = searchParams.get("deviceUuid");

        const noAuthConfig: IUserConfig = getConfigParams(experienceIdParam, deviceUuid);

        axios.defaults.baseURL = res.data?.API_SIAPHOTOS;

        return { ...res.data, ...noAuthConfig };
      }),
    {
      refetchOnReconnect: false,
    },
  );

  return {
    config,
  };
};

export default useConfigState;
