import { type FC, type PropsWithChildren, createContext } from "react";
import useIdleState from "./useIdleState";

const IdleContext = createContext<any | undefined>(undefined);

const IdleProvider: FC<PropsWithChildren> = ({ children }) => {
  const idleState = useIdleState();
  return <IdleContext.Provider value={idleState}>{children}</IdleContext.Provider>;
};

export { IdleProvider, IdleContext };
