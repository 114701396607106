export enum IExperienceNameEnum {
  Oculus = "Oculus",
  Zoomie = "Zoomie",
  Halo = "Halo",
}

export enum IExperienceType {
  Camera,
  Kiosk,
}
