const api = {
  config: "/config.json",
  getExperienceWithSelectedDeviceById: (experienceId: number, uuid: string): string =>
    `/experience/${experienceId}/device/${uuid}`,
  capture: (uuid: string): string => `/capture/${uuid}`,
  getCaptureAvailability: (): string => "/capture-availability",
  getStatus: (uuid: string): string => `/status/${uuid}`,
};

export default api;
