import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router";

import { RoutingEnum } from "@/interfaces/enums/routing.enum";
import getIsKioskExperience from "@/helpers/getIsKioskExperience";

import { defaultIdleTimerInSeconds, defaultNoActivityTimerInSeconds } from "./idle.consts";
import useExperience from "../experienceProvider/useExperience";
import type { IIdleState } from "./idle.types";

const useIdleState = (): IIdleState => {
  const { experience } = useExperience();
  const navigate = useNavigate();
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [noActivityTimerInSeconds, setNoActivityTimerInSeconds] = useState<number>(defaultNoActivityTimerInSeconds);
  const [idleTimerInSeconds, setIdleTimerInSeconds] = useState<number>(defaultIdleTimerInSeconds);

  const isExperienceKioskUsed: boolean = experience ? getIsKioskExperience(experience) : false;

  const onPrompt = (): void => {
    setIsShowingModal(true);
  };

  const handleLeave = (): void => {
    setIsShowingModal(false);
    activate();

    navigate(isExperienceKioskUsed ? RoutingEnum.scan : RoutingEnum.cover);
  };

  const onIdle = (): void => {
    handleLeave();
  };

  const { activate, pause: pauseTimer } = useIdleTimer({
    onPrompt,
    onIdle,
    promptBeforeIdle: 1000 * idleTimerInSeconds,
    timeout:
      1000 * (idleTimerInSeconds >= noActivityTimerInSeconds ? idleTimerInSeconds + 1 : noActivityTimerInSeconds),
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
  });

  const handleResetTimer = (): void => {
    activate();
    setIsShowingModal(false);
  };

  useEffect(() => {
    if (idleTimerInSeconds === 0) {
      pauseTimer();
    }
  }, [idleTimerInSeconds]);

  return {
    noActivityTimerInSeconds,
    idleTimerInSeconds,
    isPrompted: isShowingModal,
    handleLeave,
    handleResetTimer,
    pauseTimer,
    setNoActivityTimerInSeconds,
    setIdleTimerInSeconds,
  };
};

export default useIdleState;
